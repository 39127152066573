import React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import Markdown from 'react-markdown'
import styles from './About.module.css'
import GraphImg from 'graphcms-image'
import ScrollingDiv from './ScrollingDiv'

const About = ({ data: { loading, error, info, address, pictures } }) => {
  if (error) return <h1>Error fetching info!</h1>
  if (!loading) {
    document.title = 'Info'

    return (
      <article className={styles.wrapper}>
        <ScrollingDiv>
          <Markdown
            source={info.body}
            escapeHtml={false}
          />
        </ScrollingDiv>

        <div className={styles.photo}>
          <GraphImg image={pictures[0]}
                    alt={'Emmy'}
          />
        </div>
        <div className={styles.address}>
          <Markdown
            source={address.body}
            escapeHtml={false}
          />
        </div>
      </article>
    )
  }
  return <h2>Loading...</h2>
}

export const abouts = gql`
  query abouts {
    info: about (where:{type:Info}) {
      body
    }
    
    address: about (where:{type:Address}) {
      body
    }
    
    pictures: assets(where:{fileName:"emmy-full.jpg"}) {
      handle
      width
      height
    }
  }
`

export default graphql(abouts)(About)

import React from 'react'
import Link from 'react-router-dom/es/Link'
import styles from './ProjectList.module.css'
import GraphImg from 'graphcms-image'

const ProjectList = ({ projects, type }) => {
  return (
    <section className={styles.mainSection}>
      {projects.map(project => (
        <div className={styles.projectItem} key={project.id}>
          <Link to={`/${type}/project/${project.slug}`} key={project.id}>
            <GraphImg image={project.cover}
                      alt={project.title}
                      />
            {project.title}
          </Link>
        </div>
      ))}
    </section>
  )
}

export default ProjectList

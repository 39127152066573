import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import styles from './App.module.css'
import ReactGA from 'react-ga'
import Splash from './Splash'
import Content from './Content'

ReactGA.initialize('UA-8525652-2')
ReactGA.pageview(window.location.pathname + window.location.search)

const App = () => {
  return (
    <Router>
      <div className='whole'>
        <div className={styles.bodyfloater}>&nbsp;</div>
        <div className={styles.body}>
          <Switch>
            <Route exact path='/' component={Splash}/>
            <Route component={Content}/>
          </Switch>
        </div>
      </div>
    </Router>
  )
}

export default App

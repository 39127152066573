import React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import ProjectList from './ProjectList'
import capitalize from '../util/capitalize'
import { Redirect } from 'react-router-dom'

const FolderView = ({ data: { loading, error, projects, folder }, match }) => {
  if (error) return <h1>Error fetching the folders!</h1>
  if (!loading) {
    if (folder === null) {
      return (
        <Redirect to={'/'}/>
      )
    }
    const type = match.params.type
    document.title = capitalize(folder.display)

    return (
      <ProjectList projects={projects} type={type}/>
    )
  }
  return null
}

export const projects = gql`
  query projects($slug: String!) {
    folder: folder(where:{slug: $slug}) {
      id
      display    
    }
    
    projects: projects(where:{folders_some:{slug: $slug}}) {
      id
      slug
      title
      folders(first: 1) {
        type
        slug
      }  
      cover {
        width
        handle
        height
        url
      }
    }
  }
`

export default graphql(projects, {
  options: ({ match }) => ({
    variables: {
      slug: match.params.slug
    }
  })
})(FolderView)

import React from 'react'
import ReactDOM from 'react-dom'

import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'

import App from './components/App'
import './index.css'

import registerServiceWorker from './registerServiceWorker'

const GRAPHQL_ENDPOINT = 'https://api-eu-central-1.graphcms.com/v2/cjuazbp5x0yu901ghsa3fi73s/master';
//https://api-euwest.graphcms.com/v1/cjuazbp5x0yu901ghsa3fi73s/master'
// const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:1337/graphql';

const client = new ApolloClient({
  link: new HttpLink({ uri: GRAPHQL_ENDPOINT }),
  cache: new InMemoryCache()
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)
registerServiceWorker()

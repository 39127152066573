import React, { useState, useEffect } from 'react'
import styles from './ScrollingDiv.module.css'

const ScrollingDiv = props => {
  const [scrolledTo, setScrolledTo] = useState('begin')
  const [shouldScroll, setShouldScroll] = useState(false)

  const scrollTo = (el) => {
    const domElem = document.getElementById(el)
    domElem.scrollIntoView()
    setScrolledTo(el)
    return false
  }

  useEffect(() => {
    const endElem = document.getElementById('end')
    setShouldScroll(endElem.offsetLeft > 0)
  });

  return (
    <div className={styles.container}>
      <div className={styles.columns}>
        <div id='begin'/>
        {props.children}
        <div id='end'/>
      </div>
      {shouldScroll && <div className={styles.controls}>
        {scrolledTo !== 'begin' &&
        <button onClick={() => scrollTo('begin')} className={styles.left}>&lt;&lt;&lt;</button>}
        {scrolledTo !== 'end' &&
        <button onClick={() => scrollTo('end')} className={styles.right}>&gt;&gt;&gt;</button>}
      </div>
      }
    </div>
  )
}

export default ScrollingDiv

import React from 'react'
import styles from './TopMenu.module.css'
import { NavLink } from 'react-router-dom'

export default () => (
  <header>
    <nav className={styles['menu-top']}>
      <NavLink to='/architectuur'>Architectuur</NavLink>
      <NavLink to='/boeken'>Boeken</NavLink>
      <NavLink to='/info'>Info</NavLink>
    </nav>
  </header>
)

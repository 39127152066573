import React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import styles from './SubMenu.module.css'
import NavLink from 'react-router-dom/es/NavLink'

const SubMenu = ({ data: { loading, error, architectuur, boeken }, match, currentProject }) => {
  if (error) return <h1>Error fetching the submenu!</h1>
  if (!loading) {
    let activeFolder = boeken
    let activeStyles = `${styles['menu-left']} ${styles['boeken']}`

    if (match.params.type === 'architectuur') {
      activeFolder = architectuur
      activeStyles = styles['menu-left']
    }

    if (match.params.type === 'info') {
      activeFolder = []
    }

    let projectFolders = []

    if (currentProject && currentProject.folders) {
      currentProject.folders.map(folder => projectFolders.push(folder.slug))
    }

    return (
      <header>
        <nav className={activeStyles}>
          {activeFolder.map(folder => (
            <NavLink exact to={`/${folder.type.toLowerCase()}/${folder.slug}`}
                     key={folder.id} className={projectFolders.indexOf(folder.slug) < 0 ? null : styles.active}
                     activeClassName={styles.active}>
              {folder.display}
            </NavLink>
          ))}
        </nav>
      </header>
    )
  }
  return null
}

export const folders = gql`
  query folders {
    architectuur: folders (where: { type: ARCHITECTUUR } ) {
      id
      slug
      display
      type
    }
    
    boeken: folders (where: { type: BOEKEN} ) {
      id
      slug
      display
      type
    }
  }
`

export default graphql(folders)(SubMenu)

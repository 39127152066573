import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import Markdown from 'react-markdown'
import styles from './ProjectView.module.css'
import capitalize from '../util/capitalize'
import ReactGA from 'react-ga'
import GraphImg from 'graphcms-image'
import ScrollingDiv from './ScrollingDiv'
import Redirect from 'react-router-dom/es/Redirect'

const ProjectView = ({ data: { loading, error, project }, setProject }) => {
  if (error) return <h1>Error fetching the project!</h1>
  if (!loading) {
    if (project === null) {
      return (
        <Redirect to={'/'}/>
      )
    }
    const [currentImg, setCurrentImg] = useState(project.images.length > 0 ? project.images[0] : null)

    document.title = capitalize(project.title)

    useEffect(() => {
      setProject(project)
      ReactGA.pageview(window.location.pathname + window.location.search)

      return function cleanup () {
        setProject(null)
      }
    })

    return (
      <article className={styles.singleProject}>
        <div className={styles.thumbs}>
          {project.images.map(image => (
            <button onClick={() => setCurrentImg(image)} key={image.id}>
              <GraphImg image={image}
                        alt={project.title}
                        fit={'crop'}
                        width={70}
                        height={50}/>
            </button>
          ))}
        </div>
        <div className={styles.fullImg}>
          {currentImg &&
          <GraphImg image={currentImg}
                    alt={project.title}
                    fit={'crop'}
                    width={480}
                    height={345}/>
          }
        </div>
        <ScrollingDiv>
          <h1>{project.title}</h1>
          <Markdown
            source={project.body}
            escapeHtml={false}
          />
        </ScrollingDiv>
      </article>
    )
  }
  return null
}

export const project = gql`
  query project($slug: String!) {
    project(where: {slug: $slug}) {
      id
      body
      title
      images {
        id
        handle
        url
        width
        height
      }
      folders {
        id
        slug
      }
    }
  }
`

export default graphql(project, {
  options: ({ match }) => ({
    variables: {
      slug: match.params.slug
    }
  })
})(ProjectView)

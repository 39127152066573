import React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import Link from 'react-router-dom/es/Link'
import GraphImg from 'graphcms-image'
import styles from './Splash.module.css'

const Splash = ({ data: { loading, error, splashes } }) => {
  if (error) return <h1>Error fetching the splash!</h1>
  document.title = 'Emmy van Eijk'
  if (!loading) {
    const randomSplash = splashes.sort(() => Math.random() - 0.5).slice(0, 1)[0]
    return (
      <Link to="/architectuur">
        <GraphImg image={randomSplash.image}
                  alt={randomSplash.description}
                  className={styles.splash}
                  blurryPlaceholder={false}
                  fit={'max'}
                  outerWrapperClassName={styles.splashWrapper}/>
      </Link>
    )
  }
  return null
}

export const splashes = gql`
  query splashes {
    splashes {
      id
      description
      image {
        handle
        width
        height
        url
        id
      }
    }
  }
`

export default graphql(splashes)(Splash)

import React from 'react'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'

export default () => (
  <footer>
    <div className={styles.footer}>
      <Link to='/' className={styles.footer_logo}>
        <img src={'/img/logo_picture.png'} alt={'Emmy van Eijk'}/>
      </Link>
    </div>
  </footer>
)

import React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import ProjectList from './ProjectList'
import capitalize from '../util/capitalize'
import Redirect from 'react-router-dom/es/Redirect'

const TypeView = ({ data: { loading, error, projects }, match }) => {
  if (error) {
    return (
      <Redirect to={'/'}/>
    )
  }
  const type = match.params.type
  document.title = capitalize(type)
  if (!loading) {
    const randomProjects = projects.sort(() => Math.random() - 0.5).slice(0, 12)
    return (
      <ProjectList projects={randomProjects} type={type}/>
    )
  }
  return null
}

export const type = gql`
  query projects($type: FolderType!) {
    projects(where:{folders_some:{type: $type}}) {
      id
      slug
      title
      cover {
        width
        height
        handle
        url
      }
    }
  }
`

export default graphql(type, {
  options: ({ match }) => ({
    variables: {
      type: match.params.type.toUpperCase()
    }
  })
})(TypeView)

import TopMenu from './TopMenu'
import { Route, Switch } from 'react-router-dom'
import SubMenu from './SubMenu'
import withTracker from '../util/withTracker'
import About from './About'
import TypeView from './TypeView'
import FolderView from './FolderView'
import ProjectView from './ProjectView'
import Footer from './Footer'
import React, { useState } from 'react'

const Content = () => {
  const [project, setProject] = useState(0)
  return (
      <>
        <TopMenu/>
        <Route path='/:type' render={(props) => <SubMenu {...props} currentProject={project}/>}/>
        <main>
          <Switch>
            <Route exact path='/info' component={withTracker(About)}/>
            <Route exact path='/:type' component={withTracker(TypeView)}/>
            <Route exact path='/:type/:slug' component={withTracker(FolderView)}/>
            <Route path='/:type/:folder/:slug'
                   render={(props) => <ProjectView {...props} setProject={setProject}/>}/>
          </Switch>
        </main>
        < Footer/>
      </>
  )
}

export default Content